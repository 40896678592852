const local = {
  API_BASE_URL: 'http://localhost:8085/api',
  IMG_BASE_URL: 'http://localhost:8085/',
  BASE_URL: 'http://localhost:3000/',
  REDIRECT_SIGNIN: 'http://localhost:3000/signup',
  REDIRECT_SIGNOUT: 'http://localhost:3000',
  STRIPE_PUB_KEY : 'pk_test_51HC4rsG2nn2I74nFtVMEdKlypUrnhrm2mbbJE3viQmf2INP8nKkJgPyYpiC2cqLotYTXVcJ2gbkcOsoZg5XEc2O10050TYQMC9'
};

const dev = {
  API_BASE_URL: 'https://api.dev.planna.ai/api',
  IMG_BASE_URL: 'https://api.dev.planna.ai/api',
  BASE_URL: 'https://dev.planna.ai/',
  REDIRECT_SIGNIN: 'https://dev.planna.ai',
  REDIRECT_SIGNOUT: 'https://dev.planna.ai',
  STRIPE_PUB_KEY : 'pk_test_51HC4rsG2nn2I74nFtVMEdKlypUrnhrm2mbbJE3viQmf2INP8nKkJgPyYpiC2cqLotYTXVcJ2gbkcOsoZg5XEc2O10050TYQMC9'
};

const prod = {
  API_BASE_URL: 'https://api.planna.ai/api',
  IMG_BASE_URL: 'https://api.planna.ai/api',
  BASE_URL: 'https://www.planna.co/',
  REDIRECT_SIGNIN: 'https://www.planna.co/signup',
  REDIRECT_SIGNOUT: 'https://www.planna.co',
  STRIPE_PUB_KEY : 'pk_test_51HC4rsG2nn2I74nFtVMEdKlypUrnhrm2mbbJE3viQmf2INP8nKkJgPyYpiC2cqLotYTXVcJ2gbkcOsoZg5XEc2O10050TYQMC9'
};

export default prod;
