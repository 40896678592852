import React, { useCallback, useMemo, useRef, useState } from 'react';
import { api } from '../../../utils/api';
import AnimatedDots from './animatedDots';
import ConversationItem from './conversationItem';
import './index.css';
import messageIc from './messageIc.svg';
import sendIc from './sendIc.svg';
import whatsappIc from './whatsappIc.svg';

function generateUserId() {
  return 'uid_' + Math.random().toString(36).substr(2, 9);
}

export default function AnnaWidget({ type, globalState }) {
  const defaultChatThread = useMemo(() => {
    return [
      {
        data: {
          answer:
            type === 'anna'
              ? "Hi, I'm Anna, your very own virtual assistant here to assist with everyday tasks. From creating to-dos to answering questions and providing recommendations."
              : 'How can we help?',
        },
        itemType: 'answer',
      },
    ];
  }, []);

  const [chatThread, setChatThread] = useState([]);
  const chatSessionIdRef = useRef(null);
  const [question, setQuestion] = useState('');
  const [ansLoader, setAnsLoader] = useState(false);

  const suggestionData = useMemo(
    () =>
      type === 'support'
        ? globalState?.supportSuggestionQuestions
        : globalState?.annaSuggestionQuestions,
    [
      type,
      globalState?.supportSuggestionQuestions,
      globalState?.annaSuggestionQuestions,
    ],
  );

  const _resetConversation = () => {
    setChatThread([]);
    chatSessionIdRef.current = null;
    setQuestion('');
    setAnsLoader(false);
  };

  const _changeQuestion = (e) => {
    setQuestion(e.target.value);
  };

  const _inputKeyPress = (event) => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      _submitQuestion();
    }
  };

  const _submitQuestion = () => {
    let message = question;
    const lastThread = chatThread[chatThread?.length - 1];
    if (lastThread?.prePrompt) message = lastThread?.prePrompt + ' ' + message;
    onSend(message);
  };

  const onSend = async (message, IsReset = false) => {
    if (!message?.trim()) return;
    const question = {
      text: message,
      questionType: 'text',
      itemType: 'question',
    };
    _pushTheadItem(question, IsReset);
    setQuestion('');
    let params = { chatsession: chatSessionIdRef.current ?? generateUserId() };
    if (!chatSessionIdRef.current)
      chatSessionIdRef.current = params?.chatsession;
    // if (chatSessionIdRef.current) params.chatsession = chatSessionIdRef.current;
    setAnsLoader(true);
    const { status, data } = await api.POST(
      'api/chat-gpts/answer',
      message,
      params,
    );
    setAnsLoader(false);
    if (status === 200 || status === 201) {
      if (!chatSessionIdRef.current && data?.chatSession?.id) {
        chatSessionIdRef.current = data?.chatSession?.id;
      }
      if (data?.type === 4) {
        _pushTheadItem(
          {
            text: data?.prompt || question?.text,
            itemType: 'question',
            questionType: 'address',
          },
          true,
        );
      } else {
        _pushTheadItem({
          data: data,
          itemType: 'answer',
        });
      }
    } else {
      _pushTheadItem({
        data: {
          answer:
            "Sorry, We couldn't get answer for your question. Please try it again",
        },
        itemType: 'answer',
      });
    }
  };

  const _pushTheadItem = (item, IsReset) => {
    if (IsReset) {
      setChatThread([item]);
    } else {
      setChatThread((pre) => {
        pre.push(item);
        return [...pre];
      });
    }

    setTimeout(() => {
      _scrollToBottom();
    }, 300);
  };

  const _suggestionClicked = (item) => {
    const questionText = (
      item?.title +
      ' ' +
      (item?.description ?? item?.question ?? '')
    )?.trim();

    if (item?.instruction) {
      const question = {
        text: questionText,
        questionType: 'text',
        itemType: 'question',
        prePrompt: item?.prePrompt,
      };
      _pushTheadItem(question);
      _pushTheadItem({
        data: { answer: item?.instruction },
        itemType: 'answer',
        prePrompt: item?.prePrompt,
      });
    } else {
      onSend(questionText);
    }
  };

  const _scrollToBottom = () => {
    try {
      var scroll = document.getElementById(
        'planna-chat-boot-widget-container-anna-list',
      );
      scroll.scrollTop = scroll.scrollHeight;
      scroll.animate({ scrollTop: scroll.scrollHeight });
    } catch (error) {}
  };

  const _chatOnWhatsapp = () => {
    window.open(
      'https://wa.me/message/BFEG7TH6SNSEE1',
      '_blank',
      'noopener,noreferrer',
    );
  };

  const renderSuggestionItem = (item, index) => {
    return (
      <div
        role="button"
        key={String(index)}
        className="suggestion-item"
        onClick={() => _suggestionClicked(item)}
      >
        <span className="suggestion-item-title">{item?.title}</span>
        <span className="suggestion-item-desc">{item?.description}</span>
      </div>
    );
  };

  const renderItem = (item, index) => {
    return <ConversationItem key={String(index)} item={item} />;
  };

  const renderAnsLoader = useCallback(() => {
    if (!ansLoader) return null;
    return (
      <div className="conversation-item answer-item">
        <img src={messageIc} className="message-icon" />
        <p>
          Hi, let me check that for you
          <AnimatedDots />
        </p>
      </div>
    );
  }, [ansLoader]);

  const renderResetButton = useCallback(() => {
    if (ansLoader) return null;
    if (chatThread?.length)
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            onClick={_resetConversation}
            className="planna-chat-boot-widget-container-anna-reset-button"
          >
            Start a New conversation
          </button>
        </div>
      );
  }, [ansLoader, chatThread]);

  const listData = chatThread?.length ? chatThread : defaultChatThread;

  return (
    <React.Fragment>
      <section className="planna-chat-boot-widget-container-anna">
        {type === 'support' && (
          <div
            className="planna-chat-boot-widget-container-chat-whatsapp"
            onClick={_chatOnWhatsapp}
          >
            <span className="planna-chat-boot-widget-container-chat-whatsapp-span">
              Chat with us on Whatsapp
            </span>
            <img src={whatsappIc} />
          </div>
        )}

        <div
          style={type === 'support' ? { marginTop: 47 } : {}}
          className="scroll-content"
          id="planna-chat-boot-widget-container-anna-list"
        >
          <div className="planna-chat-boot-widget-container-anna-q-a-list">
            <h4 className="text-center">
              {type === 'anna' ? 'Try Anna yourself' : 'FAQ’s'}
            </h4>
            {listData?.map(renderItem)}
            {renderResetButton()}
            {renderAnsLoader()}
          </div>
          {!chatThread?.length ? (
            <div style={{ alignContent: 'end' }}>
              <div className="planna-chat-boot-widget-container-anna-suggestion-container">
                {suggestionData?.map(renderSuggestionItem)}
              </div>
            </div>
          ) : null}
        </div>

        <div className="planna-chat-boot-widget-container-anna-input-footer">
          <div>
            <input
              className="planna-chat-boot-widget-container-anna-input"
              placeholder="Enter you question"
              value={question}
              onChange={_changeQuestion}
              onKeyDown={_inputKeyPress}
              enterKeyHint="done"
            />
            <button
              className="planna-chat-boot-widget-container-anna-send-button"
              onClick={_submitQuestion}
            >
              <img src={sendIc} />
            </button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
